<template>
  <div class="home-quick-link">
    <div class="item" @click="goWxGroup">
      <img class="item-icon" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/chat_group.svg" alt="">
      <div class="item-text">加社群</div>
    </div>
    <div class="item" @click="goPc">
      <img class="item-icon" src="@/assets/svg/pc.svg"/>
      <div class="item-text">登录电脑版</div>
    </div>
    <div class="item" @click="$router.push('/rank?nav=task')">
      <img class="item-icon task" style="object-fit: contain" src="https://cdn.001ppt.cn/pc2/static/imgs/svg/yanzhi.svg" alt="">
      <div class="item-text">赚严值</div>
    </div>
  </div>
</template>


------------------ script ------------------
<script>
export default {

  methods:{

    goWxGroup() {
      window.location.href = 'https://www.001ppt.com/urlScheme?remark=微信群&path=pages/wxGroup/index'
    },


    goPc(){
      window.location.href = 'https://www.001ppt.com/urlScheme?remark=首页展示复制到PC&path=pages/tabbar/home/home&query=showGoPcDialog=true';
    }
  }

}
</script>


------------------ styles ------------------
<style lang="less" scoped>
.home-quick-link {
  height: 54px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px 16px 32px;
  background: #fff;
  //border-bottom: 1px solid rgba(21, 29, 54, 0.08);
}

.home-quick-link .item{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  flex-direction: column;
}

.item-icon{
  height: 35px;
  width: 35px;
}

.item-icon.task{
  height: 35px;
  width: 25px;
}

</style>
