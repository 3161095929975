<template>
  <div>
    <home-plans :header-height="style.headerHeight" ref="homePlans"/>
    <ai-search-input ref="aiSearchInput" @ask="aiAsk" :loading="loadings.aiAsk" :show-close="true"/>
  </div>
</template>

<script>
import HomePlans from "./components/homePlans.vue";
import {closeGlobalLoading, sleep} from "../../config/util";
import AiSearchInput from "../../components/biz/aiSearchInput.vue";
import {chatApi} from "../../api/v2/chatV2Api";

export default {
  components: {AiSearchInput, HomePlans},
  data() {
    return {
      style: {
        headerHeight: 0
      },
      loadings: {
        aiAsk: false
      }
    }
  },

  async mounted() {
    if (this.$route.query.tab === 'rank') {
      const subTab = this.$route.query.subTab
      const query = {}
      if (subTab) {
        query.subTab = subTab
      }
      return this.$router.replace({
        path: '/hotRank',
        query
      })
    }
    if(this.$route.query.orderBy === 'isRecommendedRank'){
      return this.$router.replace('/hotRank?subTab=recommended')
    }
    if(this.$route.query.orderBy  === 'hotScoreRank'){
      return this.$router.replace('/hotRank?subTab=hotScore')
    }

    let times = 0
    while (true) {
      const homeHeader = document.getElementById('home-header')
      if (homeHeader) {
        this.style.headerHeight = homeHeader.getBoundingClientRect().height
        break
      }
      await sleep(200)
      times++
      if (times > 10) {
        break
      }
    }
    this.$refs.homePlans.init()
    closeGlobalLoading()
  },


  methods: {
    // 向ai提问
    async aiAsk(question) {
      if (!this.hasLogin) {
        return this.$store.commit('SHOW_LOGIN_DIALOG')
      }
      try {
        this.loadings.aiAsk = true
        const res = await chatApi.prepare({question})
        await this.$router.push('/ai?id=' + res.id)
        // this.$refs.aiSearchInput.question = ''
      } catch (e) {
        console.error(e)
      } finally {
        this.loadings.aiAsk = false
      }
    },
  }

}
</script>

<style lang="less">
@import "components/home-list";
</style>
