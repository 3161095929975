<template>
  <div class="home-banner">
    <div class="holder skeleton-block" v-if="!banners || !banners.length"></div>
    <van-swipe indicator-color="#fff" v-else>
      <van-swipe-item v-for="item in banners" :key="item.id" v-if="item.visibleInH5">
        <a :href="item.h5Url" style="display: flex;align-items: center;height: 100%">
          <img :src="item.imgUrl" alt="">
        </a>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>


------------------ script ------------------
<script>
import {bannerV2Api} from "../../../api/v2/bannerV2Api";

export default {

  data() {
    return {
      banners: []
    }
  },

  async mounted() {
    this.banners = await bannerV2Api.findAll()
  },

  methods: {

    // 跳转至banner图位置
    async goBanner(item) {
      if (item.h5Url) {
        window.location.href = item.h5Url
      }
    },

  }
}
</script>


------------------ styles ------------------
<style lang="less" scoped>

.home-banner {
  //margin-top: 6px;
  padding: 12px 27px;
  background: #f4f4f4;
}

.home-banner img {
  width: 100%;
  border-radius: 6px;
}

.holder{
  width: calc(100vw - 54px);
  height: calc( (100vw - 54px) * 156 / 414 );
  background: #ddd;
  border-radius: 6px;
}

</style>
