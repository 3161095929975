import {v2Request} from "./v2Request";


export const keywordApi = {

  refKeywords(keyword) {
    return v2Request.get('/ai/keyword/refKeywords', {params:{keyword}})
  },

}
